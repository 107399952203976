import { Accordion, AccordionDetails, AccordionSummary } from '@achieve/ascend'
import { TypographyLongForm, Typography } from 'components/Contentful'
import { ChevronDown } from 'react-feather'
import styles from './FaqAccordion.module.scss'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { TrackingWrapper } from 'components/Tracking/TrackingWrapper'

/**
 * @param {{
 *  question: object
 * }} props
 */
const FaqAccordion = ({
  answer,
  question,
  setNumber,
  selected,
  setSelected,
  open = true,
  onChangeExpanded,
  index = 0,
  faqId = null,
  questionTextVariant,
  answerTextVariant,
}) => {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  return (
    <>
      <TrackingWrapper
        track={{
          list_name: 'FAQ ACCORDION EXPANDED',
          click_id: !(selected === setNumber) ? 'Open' : 'Close',
          click_position: index,
          click_text: question?.json?.content?.[0]?.content[0]?.value || '',
          faqId: faqId,
        }}
        onClick={onChangeExpanded}
      >
        <Accordion
          className={styles['accordion']}
          data-testid="faqs-accordion"
          expanded={selected === setNumber}
          id={`faq-${setNumber}`}
          key={setNumber}
          onChange={(event, expanded) => {
            setSelected(selected === setNumber ? '' : setNumber)
            onChangeExpanded?.(event, expanded)
          }}
          style={{ display: open ? 'block' : 'none' }}
        >
          <AccordionSummary
            className={styles['accordion-summary']}
            data-testid="faqs-question"
            expandIcon={
              <ChevronDown
                className={selected === setNumber ? styles['expand-icon-selected'] : ''}
                size={isMobile ? 24 : 32}
              />
            }
          >
            <Typography
              className={styles['accordion-summary-text']}
              content={question}
              fontWeight="medium"
              variant={questionTextVariant ?? isMobile ? 'bodyLg' : 'headingMd'}
              component="h2"
            />
          </AccordionSummary>
          <AccordionDetails className={styles['accordion-detail']} data-testid="faqs-answer">
            <TypographyLongForm
              content={answer}
              variant={answerTextVariant ?? 'bodySm'}
              component="p"
            />
          </AccordionDetails>
        </Accordion>
      </TrackingWrapper>
    </>
  )
}

export { FaqAccordion }
